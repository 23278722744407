/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A dashboard that's broken down to display sales data and tasks with priority marks. The primary goal of the application was to open communication between HQ and its brances and was used by an apparal maker and hadware supplier during my time in the project."), "\n", React.createElement(_components.p, null, "The chart at the top half will update if a user selects a category, clicks on a timeframe button, or depending on the user's role."), "\n", React.createElement(_components.p, null, "The project used a responsive design with an emphsis desktop and tablets, and older browsers (IE9)."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
